<template>
  <div>
    &emsp;Work Order{{ endString }}
    <v-card class="mb-3" outlined>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>
            <strong>Order #:&nbsp;</strong>
            {{ item.dynamic_data.OrderNumber }} -
            {{ item.dynamic_data.Lot }},&emsp; <strong>QTY:&nbsp;</strong>
            {{ item.quantity }},&emsp;
            <strong>Date Required:&nbsp;</strong>
            <span>
              {{ item.formatted_date_required }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="Date Created:"
                    disabled
                    :value="item.formatted_date_created"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="Ship to:"
                    disabled
                    :value="item.dynamic_data.ShipTo"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";

export default {
  name: "AccordionInventoryWorkOrder",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const items = computed(() => {
      return props.entities.workOrders.map((workOrder) => {
        return {
          ...workOrder,
          formatted_date_required: workOrder.date_required
            ? new Date(workOrder.date_required).toLocaleString().slice(0, 10)
            : null,
          formatted_date_created: workOrder.date_created
            ? new Date(workOrder.date_created).toLocaleString().slice(0, 10)
            : null,
        };
      });
    });

    const endString = items.length > 1 ? "s:" : ":";

    return {
      items,
      get,
      endString,
    };
  },
};
</script>
