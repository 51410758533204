<template>
  <div>
    &emsp;Emove Action{{ endString }}
    <v-card class="mb-3" outlined>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>
            <strong>QTY:&nbsp;</strong> {{ item.quantity }},&emsp;
            <strong>Date Created:&nbsp;</strong>
            <span>
              {{
                item.date_created ? $d(new Date(item.date_created), "full") : ""
              }}
            </span>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";

export default {
  name: "AccordionInventoryEmoveAction",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const items = computed(() => {
      return props.entities.emoveActions;
    });

    const endString = items.length > 1 ? "s:" : ":";

    return {
      items,
      get,
      endString,
    };
  },
};
</script>
