<template>
  <div>
    <AccordionRows
      :entities="entities"
      :fields="fieldRows"
      :isPending="currentIsPending"
    />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { useInventorySourceType } from "../../store/inventorySourceType.pinia";
import { computedRef } from "../../utils/composables";
import { useFind } from "feathers-pinia";
import { computed } from "vue-demi";
import { usePartPreSchedule } from "../../store/partPreSchedule.pinia";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import AccordionInventoryPO from "./inventory-source-types/AccordionInventoryPO.vue";
import AccordionInventoryTicket from "./inventory-source-types/AccordionInventoryTicket.vue";
import AccordionInventoryRequisition from "./inventory-source-types/AccordionInventoryRequisition.vue";
import AccordionInventoryWorkOrder from "./inventory-source-types/AccordionInventoryWorkOrder.vue";
import AccordionInventoryPrescheduleBatch from "./inventory-source-types/AccordionInventoryPrescheduleBatch.vue";
import AccordionInventoryEmoveAction from "./inventory-source-types/AccordionInventoryEmoveAction.vue";
import AccordionInventoryASN from "./inventory-source-types/AccordionInventoryASN.vue";
import AccordionInventoryInspectionBatch from "./inventory-source-types/AccordionInventoryInspectionBatch.vue";
import AccordionInventoryPrescheduleOnHand from "./inventory-source-types/AccordionInventoryPrescheduleOnHand.vue";

export default defineComponent({
  name: "InventoryAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: { AccordionRows },
  setup(props) {
    console.warn("InventoryAccordion.vue", props);

    const partPreScheduleStore = usePartPreSchedule();

    const node = computed(() => props.node);
    // @TODO: preschedule specificity (no fallback true?)
    const partPreschedule = partPreScheduleStore.getPartPrescheduleFromFlowNode(
      node,
      true
    );

    const onHandPreschedule = computed(() => {
      return (
        props.node.type === "DestinationBuffer" &&
        props.paneInfo.isManufacturedNonMRPFlow
      );
    });

    const inventorySourceType = useInventorySourceType();

    const sourceTypeIds = computedRef(() => [
      ...new Set(props.node.inventory.map((item) => item.source_type_id)),
    ]);

    const sourceTypeParams = computedRef(() => {
      return {
        query: {
          id: { $in: sourceTypeIds.value },
        },
      };
    });
    const { items: sourceTypeResults } = useFind({
      model: inventorySourceType.Model,
      params: sourceTypeParams,
    });

    const inventoryWithSourceTypes = computed(() => {
      return props.node.inventory.map((item) => {
        return {
          ...item,
          source_type: sourceTypeResults.value.find(
            (result) => result.id === item.source_type_id
          )?.source_type,
        };
      });
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        part: props.isPending.part,
        inventory: props.isPending.part,
        arrayItem: props.isPending.part,
        onHandQuantity: props.isPending.part,
        totalQty: props.isPending.part,
      };
    });

    const entities = computed(() => {
      return {
        part: props.node.part,
        // better way to do this maybe?
        tickets: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "Ticket")
          .sort(function (a, b) {
            return new Date(a.date_created) - new Date(b.date_created);
          }),
        totalQty: {
          total: inventoryWithSourceTypes.value.reduce(
            (total, item) => total + parseInt(item.quantity),
            0
          ),
        },
        onHandQuantity: {
          total: partPreschedule.value?.on_hand_qty,
        },
        pos: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "PO")
          .sort(function (a, b) {
            return (
              new Number(a.dynamic_data?.OrderNumber) -
              new Number(b.dynamic_data?.OrderNumber)
            );
          }),
        requisitions: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "Requisition")
          .sort(function (a, b) {
            return (
              new Number(a.dynamic_data?.RequistionNumber) -
              new Number(b.dynamic_data?.RequistionNumber)
            );
          }),
        workOrders: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "Work Order")
          .sort(function (a, b) {
            return new Date(a.date_required) - new Date(b.date_required);
          }),
        prescheduleBatchs: inventoryWithSourceTypes.value.filter(
          (item) => item.source_type === "Preschedule Batch"
        ),
        emoveActions: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "Emove Action")
          .sort(function (a, b) {
            return new Date(a.date_created) - new Date(b.date_created);
          }),
        asns: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "ASN")
          .sort(function (a, b) {
            return new Date(a.date_created) - new Date(b.date_created);
          }),
        inspectionBatchs: inventoryWithSourceTypes.value
          .filter((item) => item.source_type === "Inspection Batch")
          .sort(function (a, b) {
            return (
              new Number(a.dynamic_data?.UserKey) -
              new Number(b.dynamic_data?.UserKey)
            );
          }),
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow(
          "Part Number",
          [
            entityRowField("part", "part_number", {
              label: "Part Number",
              colAttributes: {
                cols: 6,
                sm: 6,
                class: "px-2",
              },
            }),
            !onHandPreschedule.value
              ? entityRowField("totalQty", "total", {
                  label: "Total Quantity",
                  colAttributes: {
                    cols: 6,
                    sm: 6,
                    class: "px-2",
                  },
                })
              : null,
            onHandPreschedule.value
              ? entityRowField("onHandQuantity", "total", {
                  label: "On Hand Quantity",
                  colAttributes: {
                    cols: 6,
                    sm: 6,
                    class: "px-2",
                  },
                })
              : null,
          ].filter((item) => item !== null)
        ),
        entities.value.tickets.length > 0
          ? entityRow("Ticket", [], {
              component: AccordionInventoryTicket,
            })
          : null,
        entities.value.asns.length > 0
          ? entityRow(
              "ASN" + (entities.value.asns.length > 1 ? "s:" : ":"),
              [],
              {
                component: AccordionInventoryASN,
              }
            )
          : null,
        entities.value.pos.length > 0
          ? entityRow("PO", [], {
              component: AccordionInventoryPO,
            })
          : null,
        entities.value.requisitions.length > 0
          ? entityRow("Requisition", [], {
              component: AccordionInventoryRequisition,
            })
          : null,
        entities.value.workOrders.length > 0
          ? entityRow("Work Order", [], {
              component: AccordionInventoryWorkOrder,
            })
          : null,
        entities.value.prescheduleBatchs.length > 0
          ? entityRow("Preschedule Batch", [], {
              component: AccordionInventoryPrescheduleBatch,
            })
          : null,
        entities.value.emoveActions.length > 0
          ? entityRow("Emove Action", [], {
              component: AccordionInventoryEmoveAction,
            })
          : null,
        entities.value.inspectionBatchs.length > 0
          ? entityRow("Inspection Batch", [], {
              component: AccordionInventoryInspectionBatch,
            })
          : null,
        onHandPreschedule.value
          ? entityRow("On Hand Preschedule", [], {
              component: AccordionInventoryPrescheduleOnHand,
            })
          : null,
      ].filter((item) => item !== null);
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
});
</script>
<style scoped>
::v-deep .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

::v-deep .v-expansion-panel-header {
  flex-flow: wrap;
}
</style>
