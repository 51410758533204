<template>
  <div>
    &emsp;Preschedule On Hand
    <v-card class="mb-3" outlined>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>QTY:&nbsp;</strong>
            {{ entities.part.part_preschedule.on_hand_qty }}
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AccordionInventoryPrescheduleOnHand",
  props: {
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
