<template>
  <div>
    &emsp;ASN{{ endString }}
    <v-card class="mb-3" outlined>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>
            <strong>Facility :&nbsp;</strong>
            {{ item.facility_code }},&emsp; <strong>QTY:&nbsp;</strong>
            {{ item.quantity }},&emsp;
            <strong>Date Expected Arrival:&nbsp;</strong>
            <span>
              {{ item.formatted_date_expected_arrival }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="Vendor:"
                    disabled
                    :value="item.vendor_name"
                  />
                </v-col>
                <v-col cols="12" md="2" sm="12" class="px-2">
                  <v-text-field
                    label="Status:"
                    disabled
                    :value="item.dynamic_data.Status"
                  />
                </v-col>
                <v-col cols="12" md="4" sm="12" class="px-2">
                  <v-text-field
                    label="Date Created:"
                    disabled
                    :value="item.formatted_date_created"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";
import { useFacilities } from "../../../store/facilities.pinia";
import { useVendor } from "../../../store/vendor.pinia";
import { useFind } from "feathers-pinia";
import { computedRef } from "../../../utils/composables";

export default {
  name: "AccordionInventoryASN",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const facilitiesStore = useFacilities();
    const vendorStore = useVendor();

    const vendorIds = computed(() => {
      return props.entities.asns.map((asn) => {
        return asn.dynamic_data.VendorId.toLowerCase().trim();
      });
    });

    const vendorQuery = computedRef(() => {
      return {
        query: {
          id: { $in: vendorIds.value },
        },
      };
    });

    const { items: vendorResults } = useFind({
      model: vendorStore.Model,
      params: vendorQuery,
      queryWhen: computed(() => !!vendorQuery.value?.query.id.$in.length),
    });

    const items = computed(() => {
      return props.entities.asns.map((asn) => {
        return {
          ...asn,
          formatted_date_expected_arrival: asn.dynamic_data.DateExpectedArrival
            ? new Date(asn.dynamic_data.DateExpectedArrival)
                .toLocaleString()
                .slice(0, 10)
            : null,
          formatted_date_created: asn.date_created
            ? new Date(asn.date_created).toLocaleString().slice(0, 10)
            : null,
          facility_code: facilitiesStore.getFacilityCode(
            asn.dynamic_data.FacilityId.toLowerCase()
          ),
          vendor_name: vendorResults.value.find(
            (item) => item.id === asn.dynamic_data.VendorId.toLowerCase()
          )?.name,
        };
      });
    });

    const endString = items.length > 1 ? "s:" : ":";

    return {
      items,
      get,
      endString,
    };
  },
};
</script>
