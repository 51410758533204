<template>
  <div>
    &emsp;Ticket{{ endString }}
    <v-card class="mb-3" outlined>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-for="ticket in tickets" :key="ticket.id">
          <v-expansion-panel-header>
            <span v-if="ticket.dynamic_data.MESScanCode">
              <strong>Scan Code:&nbsp;</strong>
              {{ ticket.dynamic_data.MESScanCode }}
            </span>
            <span v-else>
              <strong>Move Ticket:&nbsp;</strong>
              {{ ticket.dynamic_data.MoveTicket }}
            </span>
            ,&emsp; <strong>QTY:&nbsp;</strong> {{ ticket.quantity }},&emsp;
            <strong>Date Created:&nbsp;</strong>
            {{
              ticket.date_created
                ? $d(new Date(ticket.date_created), "full")
                : ""
            }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="MES Scan Code"
                    disabled
                    :value="ticket.dynamic_data.MESScanCode"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="Move Ticket"
                    disabled
                    :value="ticket.dynamic_data.MoveTicket"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="Date Created"
                    disabled
                    :value="ticket.formatted_date_created_w_timestamp"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="px-2">
                  <v-text-field
                    label="PO Number"
                    disabled
                    :value="ticket.dynamic_data.OrderNumber"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";

export default {
  name: "AccordionInventoryTicket",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const tickets = computed(() => {
      return props.entities.tickets.map((ticket) => {
        return {
          ...ticket,
          formatted_date_created_w_timestamp: ticket.date_created
            ? new Date(ticket.date_created).toLocaleString()
            : null,
        };
      });
    });

    const endString = tickets.length > 1 ? "s:" : ":";

    return {
      tickets,
      get,
      endString,
    };
  },
};
</script>
