<template>
  <div>
    &emsp;PO{{ endString }}
    <v-card class="mb-3" outlined>
      <v-expansion-panels accordion multiple>
        <v-expansion-panel v-for="po in pos" :key="po.id">
          <v-expansion-panel-header>
            <strong>Order #:&nbsp;</strong>
            {{ po.dynamic_data.OrderNumber }},&emsp;
            <strong>QTY:&nbsp;</strong> {{ po.quantity }},&emsp;
            <strong>Date Req:&nbsp;</strong>
            <span>
              {{ po.formatted_date_required }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-text-field
                label="Date Created:"
                disabled
                :value="po.formatted_date_created"
              />
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";

export default {
  name: "AccordionInventoryPO",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const pos = computed(() => {
      return props.entities.pos.map((po) => {
        return {
          ...po,
          formatted_date_required: po.date_required
            ? new Date(po.date_required).toLocaleString().slice(0, 10)
            : null,
          formatted_date_created: po.date_created
            ? new Date(po.date_created).toLocaleString().slice(0, 10)
            : null,
        };
      });
    });

    const endString = pos.length > 1 ? "s:" : ":";

    return {
      pos,
      get,
      endString,
    };
  },
};
</script>
